import React from 'react'
import {MainLayout} from '../../layout'
import {LoginForm} from '../../components/auth'
import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import {isEmailVerified, logIn, resendAccountConfirmationCode, sendEmailConfirmationCode} from '../../backend'
import {useAuth} from '../../hooks'
import {navigate} from '../../utils'

export default function Login() {
  const {setUser, setEmailVerified, setCredentials} = useAuth()
  const [accountConfirmed] = useQueryParam('account_confirmed', BooleanParam)
  const [emailConfirmed] = useQueryParam('email_confirmed', BooleanParam)
  const [passwordReset] = useQueryParam('password_reset', BooleanParam)
  const [returnTo] = useQueryParam('return_to', StringParam)


  const handleLogin = async ({phone, password}) => {
    setCredentials({phone, password})
    const user = await logIn({phone, password})
    const emailVerified = await isEmailVerified()
    setUser(user)
    setEmailVerified(emailVerified)
    if (!emailVerified) {
      await sendEmailConfirmationCode()
      navigate(`/auth/confirm-email?phone=${encodeURIComponent(phone)}`)
      return
    }
    navigate(returnTo || '/manage/spaces')
  }

  const handleConfirmPhone = async ({phone}) => {
    await resendAccountConfirmationCode({phone})
    navigate(`/auth/confirm-phone?phone=${encodeURIComponent(phone)}`)
  }

  return (
    <MainLayout onNavigate={navigate}>
      <LoginForm onLogin={handleLogin}
                 onConfirmPhone={handleConfirmPhone}
                 showPhoneConfirmationSuccess={accountConfirmed}
                 showEmailConfirmationSuccess={emailConfirmed}
                 showPasswordResetSuccess={passwordReset}
      />
    </MainLayout>
  )
}
